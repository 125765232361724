
#navbar {
	position: fixed;

	font-size: 2em;

	display: flex;
	flex-direction: row;
	height: 100%;

	pointer-events: none;

	z-index: 3;

	filter: drop-shadow(5px 5px 2px rgba(0.1, 0.1, 0.1, 0.2));
}

#nav-fuzz-overlay {
	position: fixed;
	top: 0;
	left: 0;

	height: 100vh;
	width: 100vw;
	pointer-events: none;

	background-color: rgba(0, 0, 0, 0);

	z-index: -2;

	transition: background-color 0.5s ease-out;
}

.show #nav-fuzz-overlay {
	pointer-events: all;
	background-color: rgba(0, 0, 0, 0.4);
}

#navbar .nav-title {
	flex: 0.5;

	font-size: 0.5em;

	transition: flex 0.3s ease-in 0.5s;

	overflow: hidden;

	margin-left: 0.2em;
}

#navbar button[aria-expanded=true] .nav-title {
	flex: 0;
}

#navbar .nav-title-wrap {
	display: flex;
	flex-direction: row;
	pointer-events: visible;

	position: relative;
	top: 4vh;
	width: fit-content;
	height: fit-content;

	transform: translateX(-1px);
}

#navbar .nav-tab-bg {
	content: "";

	background: url("./img/white_velvet.png"), var(--pal-opulence);
	background-repeat: repeat;
	background-size: 72vh;

	width: 100%;
	height: 100%;

	position: absolute;
	z-index: -1;

	clip-path: polygon(0% 5%, 89% 0%, 100% 80%, 0% 100%);
}

#sidenav-panel {
	height: 100%;
	pointer-events: auto;

	background: url("./img/white_velvet.png"), var(--pal-opulence);
	background-repeat: repeat;
	background-size: 72vh;

	display: flex;
	flex-direction: column;

	justify-content: space-between;

	width: 30vw;
	min-width: 53vh;

	position: relative;

	overflow: hidden;
}

#account-details {
	display: flex;
	flex-direction: row;

	font-size: x-large;

	justify-content: space-between;
	position: relative;

	top: 2vh;

	width: 86%;
	margin-left: 5%;
}

#navbar #nav-collapse-button {
	padding: 0 6px 0 6px;

	background: none;

	background-repeat: repeat;
	background-size: 72vh;

	color: black;

	margin-top: auto;
	margin-bottom: auto;

	border-radius: 0;

	border: none;

	font-size: calc(1em + 2vw);

	height: 2.5em;

	display: flex;
	flex-direction: column;

	justify-content: center;
}

#navbar button * {
	transition: flex 0.3s ease-out 0.3s;

	overflow: hidden;

	flex: 1;
	align-items: center;
}

#navbar button[aria-expanded=false] .close-icon {
	flex: 0;
}

#navbar button[aria-expanded=true] .open-icon {
	flex: 0;
}

#navbar .nav-entries {
	flex: 1;
	position: relative;

	pointer-events: none;

	filter: var(--puppet-shadows);

	font-size: 3.5vh;

	padding-top: 18%;

	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
}

#navbar .nav-entries > div {
	position: relative;
	overflow: visible;
	flex: 1;

	display: flex;
	flex-direction: row;
	justify-content: end;
	align-items: center;
}

#navbar .nav-entries > div > div {
	position: relative;
	overflow: visible;
	flex: 1;

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

#navbar .nav-entries a {
	display: block;
	position: absolute;

	color: var(--pal-opulence);
	background-color: var(--pal-sanguine);

	text-decoration-line: none;


	pointer-events: all;

	height: 7em;
	width: 10em;

	padding: 2em;

	overflow: visible;
}

#navbar .nav-entries a:focus {
	outline: none;
}

#navbar .nav-entries a:hover, #navbar .nav-entries a:focus-visible {
	color: var(--pal-haze);
}


.disclaimer-clipping {
	background-color: var(--pal-nebula);
	filter: var(--puppet-shadows);

	margin: 9px;
	padding: 0.5em;

	clip-path: polygon(0% 0%, 100% 5%, 98% 95%, 0% 100%);
}

.no-need-to {
	height: 100%;
	width: 100%;

	white-space: pre-wrap;

	overflow: visible;
	pointer-events: none;
}

.not-worried {
	display: block;
}

.worry-holder {
	display: none;
	line-height: 1.4em;
}

.worrying-character {
	position: relative;
	transition: font-size 1.0s ease-in-out;
	height: 1em;
	display: none;
}

a:hover .worry-holder > .worrying-character, a:focus-visible .worry-holder > .worrying-character {
	display: inline;
}

a:hover .worrying-character.chilled-out, a:focus-visible .worrying-character.chilled-out {
	display: inline-block;
	font-family: 'Cedarville Cursive', cursive;
	font-size: larger;
}

a:hover .worrying-character.EXTRA-WORRYING, a:focus-visible .worrying-character.EXTRA-WORRYING {
	/* THEY ARE ESPECIALLY WORRIED */
	display: inline-block;
	font-family: 'Frijole', sans-serif;
	font-size: larger;
}

a:hover .worry-holder, a:focus-visible .worry-holder {
	display: block;
}

a:hover .not-worried, a:focus-visible .not-worried {
	display: none;
}

@media screen and (max-width: 500px) {
	#sidenav-panel {
		width: 100vw;
		min-width: unset;
	}

	#navbar .nav-title {
		display: none;
	}
}