@import url('https://fonts.googleapis.com/css2?family=Marcellus&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Cedarville+Cursive&display=swap');

/* FRIJOLE */
@import url('https://fonts.googleapis.com/css2?family=Frijole&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Mochiy+Pop+P+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Miniver&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Mirza:wght@400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=VT323&display=swap');

:root {
  /* APPEALING COLOR LANGUAGE */
  --pal-sanguine: #AD2831;
  --pal-carmine: #640D14;
  --pal-opulence: #D6D5C9;
  --pal-haze: #9B9EA6;
  --pal-signal: #67DD3E;
  --pal-nebula: #1E253E;

  --velvet-pattern-1: none;
  --velvet-pattern-2: none;
  --velvet-pattern-3: none;

  --puppet-shadows: drop-shadow(3px 4px 2px #00000055);
}

h1 {
  text-align: center;
}

.App {
  font-family: 'Mochiy Pop P One', sans-serif;
  color: var(--pal-opulence);

  overflow: hidden;
}

.App-header {
  background: url("./img/velvet.png"), var(--velvet-pattern-1), var(--velvet-pattern-2), var(--velvet-pattern-3), var(--pal-nebula);
  background-repeat: repeat;
  background-size: 72vh;

  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  font-size: calc(15px + 2vmin);
  padding: 1.2vh;

  box-sizing: border-box;
}


.App-link {
  color: #61dafb;
}

.filigree {

  width: 100%;

  display: flex;
  flex-direction: row;

  align-items: start;
  justify-content: center;

  overflow: hidden;
}

.experience-provocation {
  text-align: center;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  transition: opacity 1s ease-in;

  width: 100%;
}

.experience-provocation > p {
  width: 70%;
  margin-left: auto;
  margin-right: auto;
}

.experience-revelation {
  background: var(--pal-opulence);

  border: none;
  font-size: inherit;
  font-family: inherit;

  text-align: center;

  color: var(--pal-sanguine);

  border-radius: 2px;
  padding: 3px;

  filter: var(--puppet-shadows);

  transition: background-color 0.3s ease-in;
}

.weekly-revelation-cutout {
  background: var(--pal-opulence);

  display: inline-block;
  width: 46%;

  padding: 11% 5%;
  box-sizing: content-box;

  clip-path: polygon(8% 0%, 99% 28%, 97% 70%, 4% 75%);
}

.weekly-revelation {
  background: #C3C2B9;

  border: none;
  font-size: inherit;
  font-family: inherit;

  text-align: center;

  color: var(--pal-sanguine);

  border-radius: 2px;
  padding: 3px;

  height: 2.6em;

  width: 100%;

  transition: background-color 0.3s ease-in;
}

input.weekly-submit {
  background: var(--pal-sanguine);
  color: var(--pal-opulence);

  border: none;
  font-size: larger;

  clip-path: polygon(0% 20%, 98% 0%, 100% 100%, 3% 92%);
  padding: 1em 0.5em;

  margin-left: -2%;
}

input.weekly-submit:hover, input.weekly-submit:focus-visible {
  color: var(--pal-signal);
}

.sign-in-out {
  clip-path: polygon(0% 0%, 100% 10%, 98% 89%, 1% 100%);
  background: var(--pal-nebula);
  color: var(--pal-opulence);
  border: none;
  padding: 0.5em 0.5em 0.7em 0.5em;
}

.sign-in-out:focus-visible, .sign-in-out:hover {
  color: var(--pal-signal);
}

.fate {
  display: block;
  background: var(--pal-opulence);

  font-size: inherit;
  font-family: inherit;
  border: none;

  padding: 4px 7px 4px 7px;
  margin-top: 1em;
  border-radius: 2px;

  margin-left: auto;
  margin-right: auto;

  filter: var(--puppet-shadows);
}

.anticipation {
  font-size: larger;
  width: 100vw;
}

.critical-month {
  display: block;
  margin-left: auto;
  margin-right: auto;

  color: black;
  background: var(--pal-opulence);
  width: fit-content;

  padding: 4px 7px 4px 7px;
  border-radius: 2px;

  filter: var(--puppet-shadows);
}

.critical-time {
  display: block;
  margin-left: auto;
  margin-right: auto;

  color: black;
  width: fit-content;

  margin-top: 5px;

  overflow: visible;
  white-space: nowrap;

  font-size: larger;
}

.critical-time:first-child {
  margin-top: 0;
}

.tock {
  display: inline-block;

  color: black;
  background: var(--pal-opulence);

  padding: 4px 7px 4px 7px;
  border-radius: 2px;

  margin: 0 3px 0 3px;

  text-align: center;
  width: 1em;

  filter: var(--puppet-shadows);

  font-size: smaller;
}

.lickert {
  display: flex;
  flex-direction: row;
  width: 100%;

  align-items: center;
  justify-content: space-between;
}

.lickert-col {
  display: flex;
  flex-direction: column;
  height: 100%;

  align-items: center;
  justify-content: end;

  font-style: italic;

  flex: 1;
}

.lickert-label {
  flex: 0 1 12%;
  height: fit-content;
}

form {
  width: 100%;
}

form p {
  width: 70vw;
  margin-left: auto;
  margin-right: auto;

  margin-bottom: 5px;
}

form textarea {
  background: var(--pal-opulence);

  border: none;
  font-size: smaller;
  font-family: inherit;

  color: var(--pal-sanguine);

  border-radius: 2px;
  padding: 3px;

  filter: var(--puppet-shadows);

  width: 70vw;
  height: 5em;
}

hr {
  width: 70vw;
}

.site-paragraphs {
  max-width: 70vw;
}

.FAQ > h2 {
  font-weight: bolder;
  padding-top: 1em;
}

.SERVICE-NOTICE {
  background-color: #1a1e21;
  color: var(--pal-sanguine);

  font-family: "Comic Mono", monospace;
  font-size: medium;

  padding: 10px;

  width: 70vw;

  filter: var(--puppet-shadows);
}

.SERVICE-NOTICE .SERVICE-NOTICE-TITLE {
  font-size: 3em;
  font-family: "VT323", monospace;
}

.SERVICE-NOTICE b {
  font-family: "VT323", monospace;
  font-size: 1.5em;
}

.App .SERVICE-NOTICE hr {
  height: 3px;
  width: 80%;

  opacity: 100%;
  background-color: var(--pal-sanguine);
}

.initial-buffer {
  height: 1em;
}

.help-paragraphs {
  font-size: 0.7em;
  font-family: "Comic Mono", monospace;
  margin-top: 1em;
}

.App .SERVICE-NOTICE a {
  font-family: "VT323", monospace;
  font-size: 1.5em;
  color: var(--pal-signal);
}

.ad-gallery {
  width: 70vw;
  display: flex;
  flex-direction: row;

  flex-wrap: wrap;
  justify-content: center;

  text-align: center;
}

.ad-gallery > a {
  flex: 0 1 500px;
}

.ad-gallery > a > img {
  width: 100%;
  height: auto;
}


@media screen and (max-width: 410px) {
  .critical-time {
    font-size: 1em;
  }

  .lickert-label {
    font-size: 4vw;
  }
}

.Title {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Title p {
  text-align: center;
}

.App a {
  color: var(--pal-haze);
}

@media screen and (max-width: 600px) {
  .help-paragraphs {
    font-size: smaller;
  }

  .SERVICE-NOTICE {
    width: 100%;
  }

  .SERVICE-NOTICE .SERVICE-NOTICE-TITLE {
    font-size: 2em;
  }
}

@media screen and (max-width: 800px) {
  .initial-buffer {
    height: 3em;
  }
}

@media screen and (max-width: 358px) {
  .critical-time {
    width: 100%;
    overflow: hidden;
    text-wrap: normal;
  }
}