.faq-block {
	font-size: x-large;
	filter: var(--puppet-shadows);

	width: 45vw;
	min-width: 400px;

	position: relative;
	left: 30%;
}

.faq-block:nth-child(even) {
	left: unset;
	right: 30%;
}

.faq-block h2 {
	font-size: larger;
	background-color: var(--pal-carmine);

	width: 40vw;
	padding: 3em;

	position: relative;
}

.faq-block p {
	background-color: var(--pal-sanguine);
	padding: 11em 3em 3em 3em;

	margin-top: -11em;

	position: relative;
	z-index: -1;
}

.faq-block:nth-child(even) h2 {
	background-color: var(--pal-sanguine);
}

.faq-block:nth-child(even) p {
	background-color: var(--pal-carmine);
}


.faq-block.clip-1 h2 {
	clip-path: polygon(1em 0, calc(100% - 0.5em) 2em, calc(100% - 0.8em) calc(100% - 2em), 0 100%);
}

.faq-block.clip-1 p {
	clip-path: polygon(2em 7em, calc(100% - 0.5em) 0, calc(100% - 1em) 100%, 1.7em calc(100% - 2.5em));
}


.faq-block.clip-2 h2 {
	clip-path: polygon(1em 1em, calc(100% - 0.1em) 1.3em, 100% calc(100% - 2.5em), 1.2em calc(100% - 1em));
}

.faq-block.clip-2 p {
	clip-path: polygon(2.6em 0, calc(100% - 0.5em) 9.2em, calc(100% - 2.5em) calc(100% - 2.6em), 1em calc(100% - 1.2em));
}


.faq-block.clip-3 h2 {
	clip-path: polygon(0.5em 0, 100% 2.5em, calc(100% - 0.3em) calc(100% - 1.5em), 0 calc(100% - 0.6em));
}

.faq-block.clip-3 p {
	clip-path: polygon(0.7em 9.6em, calc(100% - 2.9em) 0, calc(100% - 0.9em) calc(100% - 0.1em), 2.7em 100%);
}


.faq-block.clip-4 h2 {
	clip-path: polygon(0 2.8em, calc(100% - 2em) 0, calc(100% - 1.9em) calc(100% - 2.5em), 0.1em  calc(100% - 2.6em));
}

.faq-block.clip-4 p {
	clip-path: polygon(2.8em 0, calc(100% - 0.5em) 9.2em, calc(100% - 2.2em) calc(100% - 2.6em), 1em calc(100% - 1.6em));
}

@media screen and (max-width: 1000px) {
	.faq-block {
		left: 0;
		right: unset;

		width: 95vw;
	}

	.faq-block:nth-child(even) {
		left: 0;
		right: unset;

		width: 95vw;
	}

	.faq-block h2 {
		width: 90vw;
	}
}

@media screen and (max-height: 950px) and (max-width: 500px) {
	.faq-block.clip-1 h2, .faq-block.clip-2 h2, .faq-block.clip-3 h2, .faq-block.clip-4 h2 {
		clip-path: polygon(1em 0, calc(100% - 0.5em) 2em, calc(100% - 0.8em) calc(100% - 2em), 0 100%);
	}
}