#past-streams {
	text-align: left;
}

.vod-positioner {
	overflow: visible;
	height: calc(80vh - 20vw);
}

.reversed .vod-positioner {
	height: calc(70vh - 20vw);
}

.vod-block {
	color: black;

	filter: var(--puppet-shadows);

	display: flex;
	flex-direction: row;

	align-items: flex-end;
	justify-content: center;

	position: relative;

	left: 12vw;

	height: 70vh;
}

.vod-block iframe {
	aspect-ratio: 16 / 9;
	width: 100%;

	padding: 5px;
	background-color: var(--pal-opulence);

	border-radius: 3px;

	filter: var(--puppet-shadows)
}

.yt-frame-wrap {
	flex: 3;

	display: flex;
	flex-direction: column;
	justify-content: end;
	align-items: end;
}

.reversed .yt-frame-wrap {
	align-items: start;
}

.vod-block.reversed {
	left: unset;
	right: 12vw;
}

.vod-label {
	display: block;
	text-align: right;

	font-size: large;

	color: var(--pal-opulence);

	padding-top: 0.5em;
}

.reversed .vod-label {
	border-radius: 20px 0 0 20px;
	text-align: left;
}

.vod-label > h2 {
	font-size: xx-large;
}

.riddlecraft-stream-sponsor {
	width: min-content;
	height: min-content;
}

.riddlecraft-stream-sponsor img {
	width: 30vw;
	height: auto;
}

#twitch-sponsor-block {
	margin-top: 1em;

	width: 70vw;

	align-self: center;

	filter: var(--puppet-shadows);
}

#twitch-cinema-frame {
	width: 100%;
	background-color: black;

	padding-top: 20px;

	display: flex;
	flex-direction: row;
	justify-content: center;
}

#twitch-live {
	width: 80%;
}

#twitch-live iframe {
	width: 100%;
	aspect-ratio: 16 / 9;
}

@media screen and (max-width: 600px) {
	.vod-block {
		left: 0;
		right: 0;

		width: 100vw;
	}

	.vod-label {
		text-align: center;

		font-size: 0.5em;
	}

	.reversed .vod-label {
		text-align: center;

		font-size: smaller;
	}

	.yt-frame-wrap {
		justify-content: center;
	}
}